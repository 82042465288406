import React from "react"
import SEO from "../components/seo"
import { FaArrowRight, FaEnvelope } from 'react-icons/fa'

import "../scss/youpal.scss"

import HeaderLogoImg from "../assets/img/youPal/youPalLogo.png"
import Creating from "../assets/img/youPal/IconPieCart.png"
import Optimization from "../assets/img/youPal/Icon2.png"
import Matching from "../assets/img/youPal/IconLineChart.png"
import Img1 from "../assets/img/youPal/people.png"
import Img2 from "../assets/img/youPal/composition.png"
import Icon1 from "../assets/img/youMedico/Icon1.png"
import Icon2 from "../assets/img/youMedico/Icon2.png"
import Icon3 from "../assets/img/youMedico/Icon3.png"
import Icon4 from "../assets/img/youMedico/Icon4.png"
import Icon5 from "../assets/img/youMedico/Icon5.png"

const YouPalPage = () => {
    return (
        <div>
            <SEO title="YouPal" keywords={[`youpal`, `youpal group`, `youPal`]} />
            <div className="youPalMain">
                <section className="container">
                    <div className="col">
                        <img className="headerLogo" src={HeaderLogoImg} />
                        <div className="internalMainHeader bugP">
                            <h2 className="internalNarrowCol">Creating Smarter, Better Future For You, Me, and Us</h2>
                        </div>
                        <p className="internalNarrowCol">
                            Youpal is the people and innovation-oriented company, which goes hand in hand with technological advancement and customers’ desires. By this we mean that You are the one who gives Us the reason, to create amazing projects, which are capable of making every size business more efficient and valuable. 
                        </p>
                    </div>
                </section>

                <div className="mainBody">
                    <section className="container">
                        <div className="col">
                            <span className="internalNarrowCol internalSmallHeader">Features</span>
                            <h3 className="internalNarrowCol">
                                We create a smart and better future 
                            </h3>
                            <p className="internalNarrowCol">
                                With Youpal, you can start from zero and advance to the MVP. We are here to guide you through the digital path to reach the technological summit of your wishes. This is our personal way of creating a positive impact on societies, businesses, and communities. 
                            </p>
                        </div>
                    </section>

                    <section className="container contactsSection">
                        <div className="row">
                            <div className="col">
                                <div><img src={Creating} /></div>
                                <h4>Creating</h4>
                                <p>
                                    We can create virtual wings and make your idea fly over. We have a number of frameworks and strategies, which will best suit your product and request. We can be co-creators, designers, innovators, inventors, and thus give a digital breath to your idea and desires.
                                </p>
                            </div>
                            <div className="col">
                                <div><img src={Optimization} /></div>
                                <h4>Optimisation</h4>
                                <p>
                                    You can be good, but you can also always be better. With the constant changes and challenges in the digital industry, it is hard to keep up with all the modernity. Thus, we can optimise your products and services in the most modern way, so your business and product should be less volatile towards the market changes. 
                                </p>
                            </div>
                            <div className="col">
                                <div><img src={Matching} /></div>
                                <h4>Matching</h4>
                                <p>
                                    Imperfections are things that make us all equal and make us all humans. Though, because we are humans, we can progress to each other with the assistance and finding the best talents and best matches. 
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="container internalSection">
                        <div className="row TwoCol">
                            <div className="col">
                                <img className="sideImg" src={Img1} />
                            </div>
                            <div className="col">
                                <div className="col sideContent">
                                    <div className="internalSmallSecondaryHeader text-left">Analytics</div>
                                    <h3 className="text-left">We work hard, to see you progress </h3>
                                    <p>
                                        Sometimes we all can be in rush and burning in time, in Youpla you will always have a backup from the extremely experienced and capable team, that will put everything back on the shelves and bring you the feeling of confidence and reliance. <br />
                                        Youpal has not once proven the ability and capability to deal with the industries like: <br />
                                        Banking  <br />
                                        Medicine  <br />
                                        Tourism  <br />
                                        IWG <br />
                                        Communications  <br />
                                        NGO
                                    </p>
                                    <div className="buttonArea">
                                        <button type="button" className="btn btn-primary px-4">
                                            <span>Join Us</span>
                                            <FaArrowRight style={{ fill: 'white' }} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="container exploreSection">
                        <div className="row">
                            <div className="col">
                                <div className="internalSmallSecondaryHeader">Features</div>
                                <h3>The main value of the company is our value and belief</h3>
                                <div className="row">
                                    <div className="exploreFeature">
                                        <div className="row">
                                            <div><img src={Icon1} /></div>
                                            <div className="col sideContent">
                                                <h4>Experience</h4>
                                                <p>
                                                    We create the experiences, which You give Us to experience. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exploreFeature">
                                        <div className="row">
                                            <div><img src={Icon2} /></div>
                                            <div className="col sideContent">
                                                <h4>Strategy</h4>
                                                <p>
                                                    Our main strategy is to make Your desires come true, in the best possible variation. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exploreFeature">
                                        <div className="row">
                                            <div><img src={Icon3} /></div>
                                            <div className="col sideContent">
                                                <h4>Capability</h4>
                                                <p>
                                                    We are capable of making You satisfied via making Your ideas come true and working. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exploreFeature">
                                        <div className="row">
                                            <div><img src={Icon4} /></div>
                                            <div className="col sideContent">
                                                <h4>Knowledge</h4>
                                                <p>
                                                    Youpal is the knowledge and experience, gained from our customers, which we are ready to share with You. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exploreFeature">
                                        <div className="row">
                                            <div><img src={Icon5} /></div>
                                            <div className="col sideContent">
                                                <h4>Your support & trust </h4>
                                                <p>
                                                    I am because You are, and We will be until we have your trust and support.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="container internalSection">
                        <div className="row TwoCol">
                            <div className="col">
                                <div className="col sideContent">
                                    <div className="internalSmallSecondaryHeader">Impact</div>
                                    <h3>How can We  impact you and your business? </h3>
                                    <p>
                                        We believe in the uniqueness and efficiency of your business, this is why we want to make it even more efficient. Via optimising your workflow and creating the most customer-friendly environment, your business soon will catch you up with the most modern tech-advancements. 
                                    </p>
                                    <p>
                                        We can easily predict the future of your business with us. Smart and better! 
                                    </p>
                                </div>
                            </div>
                            <div className="col">
                                <img className="sideImg" src={Img2} />
                            </div>
                        </div>
                    </section>

                    <section className="container">
                        <div className="row contactBox">
                            <div className="col">
                                <h2>Request a quote</h2>
                                <p>
                                    “contact us for more information”
                                </p>
                                <div className="sendLinkContainer">
                                    <div className="input-group">
                                        <FaEnvelope size={25} />
                                        <input type="text" className="form-control" placeholder="Your Email" aria-label="Your Email" aria-describedby="button-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default YouPalPage
